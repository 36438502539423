import { useCallback, useEffect, useMemo, useState } from 'react'
import { getChat, getChats, getMessgaes, markChatAsRead, sendMessage } from '../api/messages'
import { createSocket, SOCKET_EVENT_CONNECT, SOCKET_EVENT_DISCONNECT, SOCKET_EVENT_JOIN_CHAT, SOCKET_EVENT_NEW_MESSAGE, SOCKET_EVENT_USER_CONNECTED } from '../socket/socket-io'
import { handleErrorResponse } from '../utlils/general'
import { useLoading } from '../contexts/loading'
import { useAuth } from '../contexts/auth'

const useChatMessages = (idOrParams) => {
  const [webSocket, setWebSocket] = useState(null)
  const [currentChat, setCurrentChat] = useState(null)
  const [messages, setMessages] = useState([])
  const [chatLoaded, setChatLoaded] = useState(false)
  const { auth, setAuth } = useAuth()
  const { setLoading } = useLoading()

  const updateMessages = useCallback((newMessage) => {
    setMessages((oriMessages) => {
      let messageExist = false
      let newMessages = oriMessages.map((oriMessage) => {
        if (oriMessage.message_id === newMessage.message_id) {
          messageExist = true
          return newMessage
        }
        return oriMessage
      })
      if (!messageExist) {
        newMessages = [...oriMessages, newMessage]
      }
      return newMessages
    })
  }, [])

  const fetchChatFromApi = useCallback(() => {
    const chatId = typeof idOrParams === 'string' ? idOrParams : null
    const params = typeof idOrParams === 'object' ? idOrParams : {}
    if (!chatId && !Object.keys(params || {}).length) {
      return Promise.resolve()
    }
    const chatParams = {
      eager: 'chat_members.user', // use eager for limit 1 handling
      ...params,
    }
    setLoading(true)
    const getChatCall = () => {
      if (chatId) {
        return getChat(chatId, chatParams)
          .then(({ data: chat }) => {
            return chat
          })
      }
      return getChats(chatParams)
        .then(({ data: chats }) => {
          return chats[0]
        })
    }
    return getChatCall()
      .then((chat) => {
        if (chat) {
          setCurrentChat(chat)
        }
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth)
      })
      .finally(() => {
        setChatLoaded(true)
      })
  }, [idOrParams, setLoading, setAuth])

  const fetchMessagesFromApi = useCallback(() => {
    if (!currentChat || !chatLoaded) {
      if (chatLoaded) {
        setLoading(false)
      }
      return Promise.resolve()
    }
    const messageParams = {
      join: 'sender',
      chat_id: currentChat.chat_id,
      cleared: false,
      orderBy: 'send_time',
    }
    setLoading(true)
    return getMessgaes(messageParams)
      .then(({ data: msgs }) => {
        setMessages(msgs)
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [currentChat, chatLoaded, setLoading, setAuth])

  const onSendMessageToApi = useCallback((content) => {
    if (!currentChat) {
      return Promise.resolve()
    }
    const msgBody = { content }
    setLoading(true)
    return sendMessage(currentChat.chat_id, msgBody)
      .catch((err) => {
        handleErrorResponse(err, setAuth)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [currentChat, setLoading, setAuth])

  const markChatAsReadByApi = useCallback(() => {
    if (!currentChat) {
      return Promise.resolve()
    }
    const body = { unread: false }
    return markChatAsRead(currentChat.chat_id, body)
      .catch((err) => {
        handleErrorResponse(err, setAuth)
      })
  }, [currentChat, setAuth])

  useEffect(() => {
    if (!chatLoaded) {
      fetchChatFromApi()
    }
  }, [chatLoaded, fetchChatFromApi])

  useEffect(() => {
    fetchMessagesFromApi()
  }, [fetchMessagesFromApi])

  useEffect(() => {
    markChatAsReadByApi()
  }, [markChatAsReadByApi])

  useEffect(() => {
    const socket = createSocket()
    if (currentChat) {
      socket.connect()
      setWebSocket(socket)
      return () => {
        socket.disconnect()
        setWebSocket(null)
      }
    }
  }, [currentChat])

  useEffect(() => {
    if (webSocket && currentChat && auth.user) {
      webSocket.on(SOCKET_EVENT_CONNECT, () => {
        webSocket.emit(SOCKET_EVENT_JOIN_CHAT, currentChat.chat_id)
        webSocket.emit(SOCKET_EVENT_USER_CONNECTED, auth.user.user_id)
      })

      webSocket.on(SOCKET_EVENT_DISCONNECT, () => {
        console.warn('disconnected')
      })

      webSocket.on(SOCKET_EVENT_NEW_MESSAGE, (receivedMessage) => {
        updateMessages(receivedMessage)
        markChatAsReadByApi()
      })

      webSocket.io.on('error', (err) => {
        console.warn('err', err)
      })
    }
    return () => {
      if (webSocket && currentChat && auth.user) {
        webSocket.off()
      }
    }
  }, [currentChat, auth.user, webSocket, updateMessages, markChatAsReadByApi])

  return useMemo(() => ({
    chat: currentChat,
    messages,
    webSocket,
    fetchChat: fetchChatFromApi,
    fetchMessages: fetchMessagesFromApi,
    sendChatMessage: onSendMessageToApi,
  }), [currentChat, messages, webSocket, fetchChatFromApi, fetchMessagesFromApi, onSendMessageToApi])
}

export default useChatMessages
