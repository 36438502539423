import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex } from '@rebass/grid'
import styled from 'styled-components'
import { palette, size } from 'styled-theme'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Text from '../../atoms/Text'
import Button from '../../atoms/Button'
import Viewport from '../../atoms/Viewport'
import ContentBox from '../../atoms/ContentBox'
import AddSenseGroupView from '../../molecules/AddSenseGroupView'
import { verifyEmail } from '../../../api/auth'
import { getErrorMessage } from '../../../utlils/form'
import { useLoading } from '../../../contexts/loading'

const StyledViewport = styled(Viewport)`
 padding: 8rem 2rem 3rem;
 align-items: flex-start;
  @media only screen and (max-width: ${size('mobile')}) {
    padding: 4rem 0 0;
  }
`

const SuccessIcon = styled(Flex)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  border: 0.4rem solid ${palette('success', 1)};
  background: ${palette('success', 2)};
  ::after {
    content: '';
    width: 20%;
    height: 42%;
    margin-bottom: 5%;
    transform: rotate(45deg);
    border-style: solid;
    border-width: 0 0.8rem 0.8rem 0;
    border-color: ${palette('white', 0)};
  }
`

const PendingIcon = styled(Flex)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  border: 0.4rem solid ${palette('grayscale', 4)};
  background: ${palette('grayscale', 3)};
`

const EmailVerificationPage = () => {
  const navigate = useNavigate()
  const [verified, setVerified] = useState(false)
  const [failed, setFailed] = useState(false)
  const { loading, setLoading } = useLoading()
  const [searchParams] = useSearchParams()

  const email = searchParams.get('email')
  const token = searchParams.get('t')

  const onClickLeave = useCallback(() => {
    navigate('/profile', { replace: true })
  }, [navigate])

  const verifyEmailByApi = useCallback(() => {
    setLoading(true)
    return verifyEmail(email, token)
      .then(({ data }) => {
        if (data.status === 'success') {
          setVerified(true)
        }
      })
      .catch((err) => {
        console.error('[Error]', getErrorMessage(err))
        setFailed(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [email, token, setLoading])

  useEffect(() => {
    verifyEmailByApi()
  }, [verifyEmailByApi])

  return (
    <StyledViewport>
      <ContentBox justifyContent="center">
        {loading ? (
          <Flex flexDirection="column" alignItems="center" pb="10rem">
            <Box width="40%" maxWidth={180} minWidth={120} style={{ aspectRatio: 1 }}>
              <PendingIcon />
            </Box>
            <Box mt="1rem">
              <Text palette="primary" bold variant="h1" align="center">
                驗證E-mail中...
              </Text>
            </Box>
          </Flex>
        ) : (
          <Flex flexDirection="column" alignItems="center">
            <Box width="40%" maxWidth={180} minWidth={120} style={{ aspectRatio: 1 }}>
              {verified ? <SuccessIcon /> : <PendingIcon />}
            </Box>
            <Box mt="1rem">
              <Text palette={verified ? 'primary' : 'grayscale'} bold variant="h1" align="center">
                {verified && '完成驗證'}
                {failed && '驗證失敗'}
              </Text>
            </Box>
            <Box mt="1rem">
              <Text
                palette={verified ? 'primary' : 'grayscale'}
                paletteIndex={verified ? 0 : 3}
                bold
                variant="h3"
                align="center"
              >
                {verified && '你的E-mail已驗證成功，\n你現在可以正式使用Crush了！'}
                {failed && 'E-mail驗證失敗\n請稍後再試'}
              </Text>
            </Box>
            <Box mt="2rem">
              <Button onClick={onClickLeave}>立即登入</Button>
            </Box>
          </Flex>
        )}
      </ContentBox>
      <AddSenseGroupView />
    </StyledViewport>
  )
}

export default EmailVerificationPage
