import React from 'react'
import HomePage from './components/pages/Home'
import LoginPage from './components/pages/Login'
import RegisterPage from './components/pages/Register'
import ForgotPasswordPage from './components/pages/ForgotPassword'
import ResetPasswordPage from './components/pages/ResetPassword'
import EmailVerificationPage from './components/pages/EmailVerification'
import MatchesPage from './components/pages/Matches'
import ChatPage from './components/pages/Chat'
import ProfilePage from './components/pages/Profile'
import EditEventPage from './components/pages/EditEvent'

const NotFoundPage = () => <div>Not Found</div>

const routes = [
  {
    path: '/',
    element: <HomePage />,
    required: {
      auth: true,
    },
  },
  {
    path: '/login',
    element: <LoginPage />,
    required: {
      auth: false,
    },
  },
  {
    path: '/register',
    element: <RegisterPage />,
    required: {
      auth: false,
    },
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
    required: {
      auth: false,
    },
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: '/email-verification',
    element: <EmailVerificationPage />,
  },
  {
    path: '/matches',
    element: <MatchesPage />,
    required: {
      auth: true,
    },
  },
  {
    path: '/chat/:chatId',
    element: <ChatPage />,
    required: {
      auth: true,
    },
  },
  {
    path: '/profile',
    element: <ProfilePage />,
    required: {
      auth: true,
    },
  },
  {
    path: '/create-event',
    element: <EditEventPage />,
    required: {
      auth: true,
    },
  },
  {
    path: '/events/:eventId',
    element: <EditEventPage />,
    required: {
      auth: true,
    },
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export default routes
