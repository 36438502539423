import React, { useMemo, useState } from 'react'
import PropTypes, { any } from 'prop-types'
import { useTheme } from 'styled-components'
import ReactSelect from 'react-select'
import { Flex } from '@rebass/grid'
import { StyledFieldset } from '../Input'

const lengendFontSize = 12

const Select = ({ label, value, onChange, options, placeholder, disabled, clearable, size, ...props }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const theme = useTheme()

  const displayedValue = useMemo(() => (options || []).find(
    (option) => option.value === value,
  ), [options, value])

  return (
    <Flex style={{ position: 'relative' }}>
      <ReactSelect
        value={displayedValue}
        onChange={(v) => onChange(v ? v.value : null)}
        options={options}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        isDisabled={disabled}
        isSearchable={false}
        placeholder={placeholder}
        isClearable={clearable}
        styles={{
          container: () => ({
            width: '100%',
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            padding: 4,
            transition: 'transform 0.3s ease',
            ...(menuOpen ? {
              'color': theme.palette.primary[0],
              'transform': 'rotate(180deg)',
              ':hover': {
                color: theme.palette.primary[0],
              },
            } : {}),
          }),
          valueContainer: (styles) => ({
            ...styles,
            fontSize: '1rem',
            lineHeight: 1.2,
            ...(size === 'normal' ? {
              padding: '0.8rem 1rem',
            } : {}),
          }),
          menu: (styles) => ({
            ...styles,
            overflow: 'hidden',
            borderWidth: '0.125rem',
            borderColor: theme.palette.primary[0],
            boxShadow: `0 1px 5px 2px ${theme.palette.primary[5]}`,
            borderStyle: 'solid',
            marginTop: 0,
            borderTopWidth: 0,
            borderTopColor: 'transparent',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }),
          menuList: (styles) => ({
            ...styles,
            padding: 0,
          }),
          option: (styles) => ({
            ...styles,
            ...(size === 'normal' ? {
              padding: '0.5rem 1rem',
            } : {}),
          }),
          control: (styles) => ({
            ...styles,
            'boxShadow': 'none',
            'borderStyle': 'solid',
            'borderWidth': '0.125rem',
            'borderColor': 'transparent',
            'backgroundColor': disabled ? `${theme.palette.grayscale[4]}80` : theme.palette.white[0],
            '&:hover': {
              borderColor: 'transparent',
            },
            ...(menuOpen ? {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            } : {}),
          }),
          placeholder: (styles) => ({
            ...styles,
            color: theme.palette.primary[4],
            fontSize: '1rem',
          }),
        }}
        theme={(defaultTheme) => {
          return {
            ...defaultTheme,
            borderRadius: '0.5rem',
            colors: {
              ...defaultTheme.colors,
              danger: theme.palette.error[1],
              dangerLight: theme.palette.error[0],
              primary: theme.palette.primary[0],
              primary75: theme.palette.primary[2],
              primary50: theme.palette.primary[4],
              primary25: theme.palette.primary[5],
            },
          }
        }}
        {...props}
      />
      <StyledFieldset
        style={{
          transition: 'border-bottom-left-radius 0.3s, border-bottom-right-radius 0.3s',
          top: label ? -(lengendFontSize / 2 - 1) : 0,
          ...(menuOpen ? {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          } : {}),
        }}
      >
        {!!label && (
          <legend style={{ lineHeight: 1, fontSize: lengendFontSize, color: theme.palette.primary[0] }}>
            {label}
          </legend>
        )}
      </StyledFieldset>
    </Flex>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: any,
    }),
  ),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small']),
}

Select.defaultProps = {
  label: null,
  value: null,
  onChange: () => {},
  options: [],
  placeholder: null,
  disabled: false,
  clearable: false,
  size: 'normal',
}

export default Select
