import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/grid'
import Input from '../../atoms/Input'
import Button from '../../atoms/Button'

const MessageSender = ({ onSend, disabled, ...props }) => {
  const [message, setMessage] = useState('')
  return (
    <Flex {...props}>
      <Flex flex={1}>
        <Input
          placeholder="輸入訊息..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          readOnly={disabled}
          onKeyUp={(e) => {
            if (message && e.keyCode === 13) {
              setMessage('')
              return onSend(message)
            }
            return false
          }}
        />
      </Flex>
      <Flex ml="0.25rem">
        <Button
          onClick={() => {
            setMessage('')
            return onSend(message)
          }}
          disabled={disabled || !message}
        >
          送出
        </Button>
      </Flex>
    </Flex>
  )
}

MessageSender.propTypes = {
  onSend: PropTypes.func,
  disabled: PropTypes.bool,
}

MessageSender.defaultProps = {
  onSend: () => {},
  disabled: false,
}

export default MessageSender
