import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/grid'
import styled from 'styled-components'
import Image from '../components/atoms/Image'

const RotatingImage = styled(Image)`
  animation-name: self-rotation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  @keyframes self-rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
`

const LoadingContext = React.createContext()
const { Provider, Consumer: LoadingConsumer } = LoadingContext

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  return (
    <Provider value={{ loading, setLoading }}>
      {children}
      {loading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
          }}
        >
          <RotatingImage src="/logo_simple.png" height="5rem" />
        </Flex>
      )}
    </Provider>
  )
}

LoadingProvider.propTypes = {
  children: PropTypes.node,
}

export const withLoading = (Component) => {
  return (props) => (
    <LoadingConsumer>
      {({ loading, setLoading }) => (
        <Component
          loading={loading}
          setLoading={setLoading}
          {...props}
        />
      )}
    </LoadingConsumer>
  )
}

export const useLoading = () => useContext(LoadingContext)

export default LoadingContext
